/**
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React from "react";
import { Provider, Dialog } from "@fluentui/react-northstar";
import * as microsoftTeams from "@microsoft/teams-js";

import Lockr from "lockr";

import { IAppState, logging, StorageKeys, BotNotificationType } from "utils";
import {
  TeamsThemeHelper,
  RouterHelper,
  LanguageHelper,
  EnvHelper,
  GeneralHelper,
} from "utils/helpers";

import {
  messaging,
  socketAuth,
  socketManager,
  keepAlive,
  teamsStorageUtils,
} from "services";
import { IntlProvider, FormattedMessage } from "react-intl";
import { MainView, NotificationsView } from "containers";
import { serviceCall } from "services/io/service-call";
import { socketOutbound } from "services/outbound";
import { webchatNotifications } from "utils/helpers/webchat-notifications-helper";
import { supervisorManager } from "services/supervisor/supervisor-manager";
import { socketSettings } from "services/settings";
import { mainViewHelper } from "utils/helpers/main-view-helper";
import { webChatManager } from "services/io/web-chat";
import { socketVoicemails } from "services/voiceMails";
import { BotNotificationDataDTO } from "utils/domain/botNotificationDataDTO";
import { Subscription } from "rxjs";
import { socketAudio } from "services/calling";
import { socketIoStressTest } from "services/io/socketio-stress-test";
import { initialClientLoadService } from "services/initialClientLoad";
import MainViewStage3 from "containers/MainView/MainViewStage3";

export class App extends React.Component<{}, IAppState> {
  locale = navigator.language;

  subscriptionUserSignedIn: Subscription | null = null;
  subscriptionUserSignedOut: Subscription | null = null;
  subscriptionUserManuallySignedOut: Subscription | null = null;
  subscriptionUserSignedInOtherWindow: Subscription | null = null;
  subscriptionServiceCallOnInitialized: Subscription | null = null;
  subscriptionLoggingOfflineHandler: Subscription | null = null;
  subscriptionLoggingOnlineHandler: Subscription | null = null;
  subscriptionIsNewClientEnabledChange: Subscription | null = null;

  constructor(props: {}) {
    super(props);

    Lockr.prefix = EnvHelper.isStage3() ? "cc4t_s3_" : "cc4t_";

    this.state = {
      loggedIn: socketAuth.IsUserLoggedIn(),
      theme: TeamsThemeHelper.getTheme("default"),
      language: LanguageHelper.getLanguage(),
      offline: false,
      inactive: false,
      rootClass: "default-app-theme",
      storedLanguage: LanguageHelper.getLanguageFromLocalStorage(),
    };
    logging.configure({ minLevels: { "": "info" } }).registerConsoleLogger();

    microsoftTeams.app.initialize().finally(() => {
      /** Pass the Context interface to the initialize function below */
      microsoftTeams.app
        .getContext()
        .then((context) => this.initializeState(context));

      /** Registers a handler for theme changes. */
      microsoftTeams.app.registerOnThemeChangeHandler(
        this.updateTheme.bind(this)
      );
    });
  }

  componentDidMount() {
    messaging.initialize();

    if (EnvHelper.isStage3()) {
      this.checkIsNewClientEnabled();
    }

    if (!EnvHelper.isStage3()) {
      keepAlive.invokeKeepAlive();
      socketIoStressTest.start();
    }

    //to be used in stage 3 also after back end method will be implemented
    GeneralHelper.logCox(
      `in App.tsx, doing socketManager.onInitialized.subscribe`
    );
    this.subscribeToEvents();
  }

  componentWillUnmount() {
    this.subscriptionUserSignedIn?.unsubscribe();
    this.subscriptionUserSignedOut?.unsubscribe();
    this.subscriptionUserManuallySignedOut?.unsubscribe();
    this.subscriptionUserSignedInOtherWindow?.unsubscribe();
    this.subscriptionServiceCallOnInitialized?.unsubscribe();
    this.subscriptionLoggingOfflineHandler?.unsubscribe();
    this.subscriptionLoggingOnlineHandler?.unsubscribe();
    this.subscriptionIsNewClientEnabledChange?.unsubscribe();
  }

  private checkIsNewClientEnabled() {
    const isNewClientEnabled = Lockr.get<boolean>(StorageKeys.IsNewClientEnabled);
    
    this.getAndSetNewClientEnabledIfNeeded(isNewClientEnabled);
  }

  private readonly getAndSetNewClientEnabledIfNeeded = (isNewClientEnabledValue: boolean) =>{
    if (isNewClientEnabledValue === undefined) {
      socketAuth.getCompanyKey().then((tenantId) => {
        socketSettings.isNewClientEnabled(tenantId).then((isClientEnabled: boolean) => {
          Lockr.set(StorageKeys.IsNewClientEnabled, isClientEnabled || false);

          if(isClientEnabled){
            this.switchClient();
          }
        });
      })
    }

    if(isNewClientEnabledValue){
      Lockr.set(StorageKeys.IsNewClientEnabled, isNewClientEnabledValue);
      this.switchClient();
    }
  }

  switchClient = () => {
    document.cookie =
      "preview=always; max-age=60480000; SameSite=None; Secure; Partitioned;";
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  loginStateView() {
    if (!this.state.loggedIn) {
      return <RouterHelper.UnauthenticatedRoutes />;
    }

    return EnvHelper.isStage3() ? (
      <MainViewStage3 key={this.state.storedLanguage} />
    ) : (
      <MainView key={this.state.storedLanguage} />
    );
  }

  render() {
    return (
      <IntlProvider locale={this.locale} messages={this.state.language}>
        <Provider theme={this.state.theme} className={this.state.rootClass}>
          <Dialog
            backdrop
            content={<FormattedMessage id="ErrorMessage.Offline" />}
            open={this.state.offline}
          />

          <Dialog
            backdrop
            content={<FormattedMessage id="ErrorMessage.Inactive" />}
            open={this.state.inactive}
          />
          <NotificationsView />
          {this.loginStateView()}
        </Provider>
      </IntlProvider>
    );
  }

  /**
   * This will save off the Context.
   */
  initializeState(context: microsoftTeams.app.Context) {
    const id = Lockr.get<string>(StorageKeys.UserObjectId);
    const userObjectId = context.user?.id;
    const tid = context.user?.tenant?.id || "";
    const userPrincipalName = context.user?.userPrincipalName || "";

    if (id && id !== userObjectId && EnvHelper.isStage3()) {
      this.signOut();
    }

    Lockr.set(StorageKeys.UserObjectId, userObjectId || "");
    Lockr.set(StorageKeys.TIDForAzure, tid);

    localStorage.setItem(StorageKeys.UPN, userPrincipalName);
    localStorage.setItem(StorageKeys.UPNForAzure, userPrincipalName);
    localStorage.setItem(StorageKeys.TIDForAzure, tid);

    this.updateTheme(context.app.theme);
    this.setLanguage(context.app.locale);

    this.checkAuthState();
    this.checkIsNotificationReceivedFromBot(context.page.subPageId);

    Lockr.rm(StorageKeys.ServiceCallTimeout);

    // initialize signalr after teams intialization is done, as we need to get the MS sso token for signalr auth
    if (EnvHelper.isStage3()) {
      socketManager.initialize();
    }
  }

  private checkIsNotificationReceivedFromBot(data?: any) {
    if (data && data !== "") {
      const notificationData = new BotNotificationDataDTO();
      notificationData.Id = data.Id;
      notificationData.Type = data.Type;

      switch (notificationData.Type) {
        case BotNotificationType.Webchat:
          webChatManager.webChatNotificationReceivedFromBot.next(
            notificationData.Id
          );
          break;
        case BotNotificationType.MissedCall:
          mainViewHelper.missedCallNotificationReceivedFromBot.next(
            notificationData.Id
          );
          break;
        case BotNotificationType.VoiceMail:
        case BotNotificationType.Alert:
          socketVoicemails.voiceMailNotificationReceivedFromBot.next(
            notificationData
          );
          break;
        default:
          break;
      }
    }
  }

  private checkAuthState() {
    setTimeout(() => {
      if (this.state.loggedIn) {
        const token = Lockr.get<string>(StorageKeys.TokenId);

        if (!token) {
          this.setState({
            loggedIn: false,
          });

          logging.alertHandler.next(
            "Please relogin to use the CC4Teams Client in this window."
          );
        } else {
          // when logged in, check if any missing info
          const sip = Lockr.get<string>(StorageKeys.SIP);
          const companyId = Lockr.get<string>(StorageKeys.CompanyId);
          const companyKey = Lockr.get<string>(StorageKeys.CompanyKey);
          const userId = Lockr.get<string>(StorageKeys.UserId);

          if (!sip || !companyId || !companyKey || !userId) {
            this.signOut();
          }
        }
      }
    }, 1000);
  }

  private updateTheme(themeString: string | undefined): void {
    this.setState({
      theme: TeamsThemeHelper.getTheme(themeString),
      rootClass: `${themeString}-app-theme`,
    });
  }

  private setLanguage(lang: string): void {
    LanguageHelper.setLanguageToLocalStorage(lang);

    this.setState({
      language: LanguageHelper.getLanguage(),
      storedLanguage: LanguageHelper.getLanguageFromLocalStorage(),
    });
  }

  private subscribeToEvents() {
    this.subscriptionUserSignedIn?.unsubscribe();
    this.subscriptionUserSignedIn = socketAuth.userSignedIn.subscribe(() => {
      GeneralHelper.logCox(
        `in App.tsx, socketAuth.userSignedIn, will call verifySignIn`
      );
      this.verifySignIn(true);
      microsoftTeams.app
        .getContext()
        .then((context) => this.initializeState(context));
      this.setIsCustomerJourneyEnabled();
    });

    this.subscriptionUserSignedOut?.unsubscribe();
    this.subscriptionUserSignedOut = socketAuth.userSignedOut.subscribe(() => {
      this.signOut();
    });

    this.subscriptionUserManuallySignedOut?.unsubscribe();
    this.subscriptionUserManuallySignedOut =
      socketAuth.userManuallySignedOut.subscribe(() => {
        this.signOut();
      });

    this.subscriptionUserSignedInOtherWindow?.unsubscribe();
    this.subscriptionUserSignedInOtherWindow =
      socketAuth.userSignedInOtherWindow.subscribe(
        (browserSessionId: string) => {
          const currentBrowserSessionId = sessionStorage.getItem(
            StorageKeys.BrowserSessionId
          ) as string;
          if (
            currentBrowserSessionId &&
            currentBrowserSessionId !== browserSessionId
          ) {
            this.setState({
              inactive: true,
            });

            socketManager.disconnect();
          }
        }
      );

    this.subscriptionServiceCallOnInitialized?.unsubscribe();
    this.subscriptionServiceCallOnInitialized =
      serviceCall.onInitialized.subscribe(() => {
        GeneralHelper.logCox(
          `in App.tsx, subscriptionServiceCallOnInitialized, will call verifySignIn`
        );
        this.verifySignIn();

        keepAlive.sendExceptionLog();
      });

    this.subscriptionLoggingOfflineHandler?.unsubscribe();
    this.subscriptionLoggingOfflineHandler = logging.offlineHandler.subscribe(
      (result: any) => {
        if (!this.state.inactive) {
          this.setState({
            offline: true,
          });
        }
      }
    );

    this.subscriptionLoggingOnlineHandler?.unsubscribe();
    this.subscriptionLoggingOnlineHandler = logging.onlineHandler.subscribe(
      (result: any) => {
        if (this.state.offline === true) {
          window.location.reload();
        }

        this.setState({
          offline: false,
        });
      }
    );

    this.subscriptionIsNewClientEnabledChange?.unsubscribe();
    this.subscriptionIsNewClientEnabledChange = initialClientLoadService.notifyIsNewClientEnabledChange.subscribe((changedValue: boolean) => {
      this.getAndSetNewClientEnabledIfNeeded(changedValue);
    });

  }

  private readonly signOut = () => {
    if (EnvHelper.isStage3()) {
      const tokenId = Lockr.get<string>(StorageKeys.TokenId);

      if (tokenId) {
        socketAuth
          .signOut(tokenId)
          .then(() => {
            this.afterSignOut();
            teamsStorageUtils.removeTeamsScopedTokenFromStorage();
          })
          .catch((err) => {
            console.error("Error during logout:", err);
          });
      }
    } else {
      socketAuth
        .signOutStage2(Lockr.get<string>(StorageKeys.SIP))
        .then(() => {
          this.afterSignOut();
        })
        .catch((err) => {
          console.error("Error during logout:", err);
        });
    }
  };

  private readonly verifySignIn = (hasUserSignedIn: boolean = false) => {
    const tokenId = Lockr.get<string>(StorageKeys.TokenId);
    GeneralHelper.logCox(`in verifySignIn`);

    if (tokenId) {
      socketAuth
        .signInToken(tokenId, hasUserSignedIn)
        .then((response) => {
          if (response.Data) {
            const isStage3 = EnvHelper.isStage3();
            if (isStage3) {
              socketAuth.afterSignIn(response.Data);

              const userId = Lockr.get<string>(StorageKeys.UserId);
              let currentBrowserSessionId = sessionStorage.getItem(
                StorageKeys.BrowserSessionId
              ) as string;

              if (!currentBrowserSessionId) {
                currentBrowserSessionId = new Date().getTime().toString();
                sessionStorage.setItem(
                  StorageKeys.BrowserSessionId,
                  currentBrowserSessionId
                );
              }
              socketManager.notifyAllViewsAtLogin(
                userId,
                currentBrowserSessionId
              );
            }

            this.setState({
              loggedIn: true,
            });

            this.joinDefaultRooms();

            this.initialApiCalls(isStage3);

            keepAlive.agentAlive();

            webchatNotifications.notifyNewWebChatRequests();

            this.setServerClientTimeDifference();

            socketAudio.getCurrentCallSession();
          } else {
            this.setState({
              loggedIn: false,
            });
            this.signOut();
          }
        })
        .catch((err) => {
          this.afterSignOut();
          teamsStorageUtils.removeTeamsScopedTokenFromStorage();
        });
    } else {
      this.setState({
        loggedIn: false,
      });
    }
  };

  private readonly afterSignOut = () => {
    this.setState({
      loggedIn: false,
    });

    const tokenId = Lockr.get<string>(StorageKeys.TokenId);
    if (tokenId) {
      if (EnvHelper.isStage3()) {
        const userId = Lockr.get<string>(StorageKeys.UserId);
        if (userId) {
          socketManager.leaveRoom(userId);
        }
        const tenantId = Lockr.get<string>(StorageKeys.CompanyKey);
        if (tenantId) {
          socketManager.leaveRoom(`tenantId-${tenantId}`);
        }
      }
      socketManager.leaveRoom(tokenId);
    }

    keepAlive.resetAgentAvailability();

    Lockr.flush();

    microsoftTeams.app
      .getContext()
      .then((context) => this.initializeState(context));
  };

  private joinDefaultRooms() {
    if (this.state.loggedIn) {
      if (EnvHelper.isStage3()) {
        const userId = Lockr.get<string>(StorageKeys.UserId);
        if (userId) {
          socketManager.joinRoom(userId);
        }
        const tenantId = Lockr.get<string>(StorageKeys.CompanyKey);
        if (tenantId) {
          socketManager.joinRoom(`tenantId-${tenantId}`);
        }
      } else {
        const tokenId = Lockr.get<string>(StorageKeys.TokenId);
        if (tokenId) {
          socketManager.joinRoom(tokenId);
        }
      }
    }
  }

  private setServerClientTimeDifference() {
    socketSettings.getServerTime().then((result: Date) => {
      const serverClientTimeDifference =
        Date.parse(result.toString()) - Date.now();
      Lockr.set(
        StorageKeys.ServerClientTimeDifference,
        serverClientTimeDifference || 0
      );
    });
  }

  private setIsCustomerJourneyEnabled() {
    if (!EnvHelper.isStage3()) {
      socketSettings.isTeamsCustomerJourneyEnabled().then((result: boolean) => {
        Lockr.set(StorageKeys.IsCustomerJourneyEnabled, result);
      });
    }
  }

  private initialApiCallsConnected() {
    socketOutbound.isAgentOutboundAccessEnabled();

    supervisorManager.checkIsUserSupervisor();
  }

  private initialApiCallsExtended() {
    const userId = Lockr.get<string>(StorageKeys.UserId);
    initialClientLoadService.getInitialClientSettings(userId);
  }

  private initialApiCalls(isStage3: boolean) {
    if (isStage3) {
      this.initialApiCallsExtended();
    } else {
      this.initialApiCallsConnected();
    }
  }
}

export default App;
